import { ref, onMounted } from "vue";
import { getBanner } from "@/request/api/home.js";
export default {
  setup() {
    const images = ref([]);
    onMounted(async () => {
      getBanner().then(res => {
        images.value = res.data.banners;
      });
    });
    return {
      images
    };
  }
};