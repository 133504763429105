import { onMounted, reactive } from "vue";
import { getMusicList } from "@/request/api/home.js";
export default {
  setup() {
    let state = reactive({
      musicList: []
    });
    onMounted(async () => {
      let res = await getMusicList();
      state.musicList = res.data.result;
      console.dir(state);
    });
    return {
      state
    };
  }
};