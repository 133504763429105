import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import FooterPlay from "./components/FooterPlay.vue";
import { useMapState } from "./utils/useVuex";
export default {
  __name: 'App',
  setup(__props) {
    const {
      showFooterMusic
    } = useMapState(["showFooterMusic"]);
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_router_view), _withDirectives(_createVNode(FooterPlay, null, null, 512), [[_vShow, _unref(showFooterMusic)]])], 64);
    };
  }
};