import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  id: "swiperTop"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_swipe_item = _resolveComponent("van-swipe-item");
  const _component_van_swipe = _resolveComponent("van-swipe");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_swipe, {
    autoplay: 3000,
    "lazy-render": ""
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.images, image => {
      return _openBlock(), _createBlock(_component_van_swipe_item, {
        key: image
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: image.pic
        }, null, 8, _hoisted_2)]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  })]);
}